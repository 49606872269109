html, body {
  height: 100%;
  margin: 0;
}

body {
  margin: 0;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}

.footer {
  background-color: #f1f1f1;
  text-align: center;
  padding: 10px;
}
