/* Cấu hình cho phần nội dung để đồng bộ với header */
.content-section {
    padding: 0 20%; /* Padding 20% cho nội dung để đồng bộ với header */
  }
  
  /* Tùy chỉnh cho phần cột chứa văn bản */
  .text-content {
    padding: 0; /* Giảm padding bên trong text-content vì padding bên ngoài đã đủ */
  }
  
  /* Tùy chỉnh cho phần cột chứa hình ảnh */
  .image-content {
    /* display: flex; */
    justify-content: flex-end; /* Căn giữa ảnh */
    /* align-items: center; Căn giữa ảnh theo chiều dọc */
    max-width: 60%; /* Đảm bảo hình ảnh chiếm 100% chiều rộng của container */
    padding-right: 20%; /* Xóa padding bên phải */
    display: "flex"
  }
  
  /* Đảm bảo hình ảnh không vượt quá kích thước container */
  .image-content img {
    width: 100%; /* Hình ảnh chiếm toàn bộ chiều rộng */
    max-width: 100%; /* Đảm bảo không vượt quá chiều rộng container */
    object-fit: cover; /* Giữ tỷ lệ và cắt ảnh nếu cần */
    object-position: center; /* Căn giữa ảnh trong khung */
    border-radius: 8px;
  }
  
  /* Tùy chỉnh text và hình ảnh */
  .pixelated-text {
    padding-top: 5%;
    font-family: sans-serif;
    font-weight: 800;
    font-size: 2rem;
    display: flex;
  }
  